import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import MailingListForm from '../components/MailingListForm';
import SEO from '../components/Seo';
<SEO title="Home" />;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "home"
    }}>{`Home`}</h1>
    <h1 {...{
      "id": "hi-people"
    }}>{`Hi people`}</h1>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https://github.com/AryanJ-NYC/reactandtypescript.dev"
      }}>{`GitHub repository`}</a>{` to contribute!`}</p>
    <p>{`For questions that are not covered in the documentation or to chat about React + TypeScript, join our `}<a parentName="p" {...{
        "href": "https://spectrum.chat/react-and-typescript"
      }}>{`Spectrum Chat`}</a>{`.`}</p>
    <p>{`A special thank you to `}<a parentName="p" {...{
        "href": "https://twitter.com/swyx"
      }}>{`@swyx`}</a>{` for starting the `}<a parentName="p" {...{
        "href": "https://github.com/typescript-cheatsheets/react-typescript-cheatsheet"
      }}>{`React TypeScript cheatsheet`}</a>{` and inspiring the existence of this website.`}</p>
    <p>{`Lastly, tweet me at `}<a parentName="p" {...{
        "href": "https://twitter.com/AryanJabbari"
      }}>{`@AryanJabbari`}</a>{`. Words of encouragement are always welcome!`}</p>
    <MailingListForm mdxType="MailingListForm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      